.wrapper {
  height: 100vh;
  width: 100vw;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_card {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 1.5em;
  width: 28em;
  min-height: 22em;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.margin_b_1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.margin_b_2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  .logo {
    align-self: center;
    width: 300px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 601px) {
  .logo {
    align-self: center;
    width: 360px;
    margin-bottom: 30px;
  }
}

.forgot_password {
  font-size: 0.9em;
  margin-top: 1.5em;
  cursor: pointer;
  color: #1166bb;
}
._2fa_message {
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
}
._2fa_button {
  width: 140px;
  align-self: center;
}
._2fa_button_wrapper {
  margin-top: 20px;
}
.cancel_button {
  margin-top: 20px;
  cursor: pointer;
  color: #1166bb;
  font-size: 14px;
  font-weight: 700;
}
._2fa_error {
  color: red;
  margin-top: 5px;
}
.mulish_title {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 46px;
  color: #01696e;
  align-self: center;
  margin-left: 20px;
}
.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
