.table_wrapper {
  width: 80%;
  margin-top: 20px;
  align-self: center;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.button {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.table_container {
  height: calc(100vh - 200px);
  overflow: scroll;
}
.columnHeaders {
  background-color: "#e9f1fd";
}
.no_rows_overlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.folder_link:hover {
  text-decoration: underline;
}
