.crumb_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.carat {
  margin-left: 6px;
  margin-right: 6px;
}
.crumb_clickable {
  cursor: pointer;
}
