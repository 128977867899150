.wrapper {
  position: absolute;
  bottom: 0;
  right: 50px;
  width: 600px;
  height: 200px;
  z-index: 5;
  background-color: #fefefe;
  border: 1px solid #eee;
  border-radius: 10px 10px 0px 0px;
  color: #444;
  font-size: 14px;
}
.header {
  height: 50px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  color: #444;
  font-size: 14px;
  border-radius: 10px 10px 0px 0px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}
.name {
  margin-left: 10px;
  width: 500px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.percentage {
  margin-right: 10px;
}
.title {
  margin-left: 10px;
}
.cancel {
  margin-right: 16px;
  color: #1166bb;
  cursor: pointer;
}
