.header_wrapper {
  height: 55px;
  width: 100vw;
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  margin-left: 1rem;
}
.settings_icons_wrapper {
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.apps_icon_wrapper {
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hflex {
  display: flex;
  cursor: pointer;
}
.username_text {
  font-size: 0.8rem;
  color: #777;
  margin-right: 10px;
}
.mulish_title {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  color: #1b3267;
  align-self: center;
  margin-left: 10px;
}
.logout {
  cursor: pointer;
  color: #333;
}
.all_files_button {
  margin-right: 40px;
  cursor: pointer;
}
